<template>
  <router-view />
</template>

<script setup lang="ts">
import { onMounted } from 'vue'

import { useCorporativeAccount } from '@/store/corporative'
import useStore from '@/store/auth'
import { useLoading } from './composables/useLoading'
import { globalPermissionsGuard } from '@/utils/globalPermissionsGuard'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'

const corporativeAccountStore = useCorporativeAccount()
const { getCorporates } = corporativeAccountStore

const authUtore = useStore()
const { getUserInfo } = authUtore
const { user } = storeToRefs(authUtore)
const { locale } = useI18n({ useScope: 'global' })

globalPermissionsGuard()

onMounted(async () => {
  await getUserInfo()
  locale.value = user.value?.locale

  useLoading(async () => await getCorporates())
})
</script>

<style>
body,
#app {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.dash:before {
  content: '|';
  color: rgb(--var(v-green));
  color: #8dc63f;
  margin-right: 0.5rem;
}

::-webkit-scrollbar {
  width: 0.44rem;
  height: 0.44rem;
}
::-webkit-scrollbar-thumb {
  background-color: rgb(85, 85, 85, 0.35);
  border-radius: 0.19rem;
}
::-webkit-scrollbar-track {
  background: transparent;
  border-right: 0.06rem solid #c5c5c5;
}

@-moz-document url-prefix() {
  * {
    scrollbar-width: thin;
    scrollbar-color: rgba(85, 85, 85, 0.35) transparent;
  }
  ::-moz-scrollbar-thumb {
    background-color: rgba(85, 85, 85, 0.35);
    border-radius: 0.19rem;
  }

  ::-moz-scrollbar-track {
    background: transparent;
    border-right: 0.06rem solid #c5c5c5;
  }

  .scroll {
    padding-right: 0.94rem !important;
  }
}

.v-data-table-column--fixed {
  font-weight: bold;
  text-overflow: ellipsis;
  max-width: 18.75rem;
}

.v-table .v-table__wrapper > table > tbody > tr > td {
  border-bottom: none !important;
  border-right: 0.125rem solid #d8d8d8;
  max-width: 18.75rem !important;
  text-overflow: ellipsis;
  overflow: hidden;
}

.v-table .v-table__wrapper > table > tbody > tr:nth-child(even) > td {
  background-color: #efefef !important;
}

.v-table .v-table__wrapper > table > tbody > tr:nth-child(odd) > td {
  background-color: white !important;
}

table {
  border-bottom: 0.06rem solid #d8d8d8;
  word-break: break-all;
  border-collapse: inherit !important;
}

.v-table {
  background-color: transparent !important;
}

.v-pagination__item--is-active {
  background-color: #8dc63f !important;
}

.v-pagination .v-btn:hover {
  background-color: #8dc63f !important;
}

.v-btn:hover > .v-btn__overlay {
  opacity: 0 !important;
}
</style>
