<script setup lang="ts">
import greenInfoIcon from '@/assets/icons/green/info.svg'
import { TransitionRoot } from '@headlessui/vue'

import { computed, ref, toRefs, useAttrs } from 'vue'

const props = withDefaults(
  defineProps<{
    label?: string
    required?: boolean
    modelValue?: string | number | boolean | Date
    multiline?: boolean
    error?: boolean
    disabled?: boolean
    checked?: boolean
    type?: string
    icon?: string
    iconPosition?: 'start' | 'end'
    onClickButton?: () => void
    comments?: boolean
    description?: string
    tooltip?: string
    decimalPlaces?: number
    prefix?: string
  }>(),
  {
    label: '',
    required: false,
    type: 'text',
    modelValue: '',
    multiline: false,
    error: false,
    disabled: false,
    checked: false,
    icon: '',
    iconPosition: 'end',
    onClickButton: undefined,
    comments: false,
    description: undefined,
    tooltip: undefined,
    decimalPlaces: undefined,
    prefix: undefined,
  }
)

const { label, required, type } = toRefs(props)
const attrs = useAttrs()

const emit = defineEmits(['update:modelValue', 'input'])
const value = computed({
  get() {
    if (props.type === 'checkbox') {
      return props.modelValue || props.checked
    }
    return props.modelValue
  },
  set(value) {
    if (type.value !== 'number') {
      return emit('update:modelValue', value)
    }
    const regex = new RegExp(`^-?\\d+(?:\\.\\d{0,${props.decimalPlaces}})?`)
    emit('update:modelValue', value.toString().match(regex))
  },
})

const stringValue = computed({
  get: () => value.value?.toString() || '',
  set: (stringValue) => (value.value = stringValue),
})

const { class: classes, ...filteredAttrs } = attrs
defineOptions({
  inheritAttrs: false,
})

const showTooltip = ref(false)
</script>

<template>
  <div class="flex w-full flex-col" :class="classes">
    <div
      v-if="props.label && type !== 'checkbox' && type !== 'radio'"
      class="flex gap-2"
    >
      <h6
        class="text-sm font-bold text-grey-2"
        :class="{ '!text-error': error, '!text-grey-4': disabled }"
      >
        {{ props.label }} {{ required ? '*' : '' }}
      </h6>
      <div v-if="tooltip" class="relative flex">
        <img
          width="24"
          :src="greenInfoIcon"
          @mouseover="showTooltip = true"
          @mouseout="showTooltip = false"
        />
        <TransitionRoot
          :show="showTooltip"
          enter="transition-opacity duration-75"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="transition-opacity duration-150"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div
            class="absolute left-full top-0 !z-50 w-max max-w-[300px] rounded bg-grey-1 p-2 pb-4 text-sm text-grey-5"
          >
            {{ tooltip }}
          </div>
        </TransitionRoot>
      </div>
    </div>
    <p v-if="description" class="text-xs text-grey-2">{{ description }}</p>

    <button
      v-if="!['checkbox', 'radio'].includes(type) && icon"
      type="button"
      class="absolute inset-y-0 my-auto h-5 w-5"
      :class="{
        'left-2': iconPosition == 'start',
        'right-2': iconPosition == 'end',
      }"
      @click="onClickButton"
    >
      <img :src="icon" />
    </button>
    <div
      v-if="!multiline && type !== 'textarea'"
      :class="`relative mt-2 flex   ${type === 'radio' ? 'items-center' : 'items-stretch'}`"
    >
      <div
        v-if="props.prefix"
        class="absolute flex h-full w-16 items-center justify-center rounded-l-lg border-[1px] border-grey-4 bg-grey-7 px-2"
      >
        {{ prefix }}
      </div>
      <input
        v-model="value"
        v-bind="{ ...filteredAttrs }"
        :type="type"
        :disabled="disabled"
        :checked="checked"
        :class="`focus:text-black-2 rounded-xl border-[1px] border-grey-4  bg-white p-1 pl-2 text-black-0 outline-none transition-all duration-100
        ${type !== 'checkbox' && type !== 'radio' ? 'w-full' : 'mr-2'}
        ${error ? ' focus:border-error' : 'focus:border-grey-2'}
        ${error && !prefix ? 'animate-nudge' : ''}
           ${icon ? (iconPosition == 'start' ? 'pl-8' : 'pr-8') : ''}
           ${type === 'radio' ? 'size-4 accent-green' : ''}
          ${prefix ? '!pl-20' : ''}
          ${disabled ? 'opacity-50' : ''}
          `"
        :maxlength="String($attrs?.maxLength ? $attrs?.maxLength : '1000')"
        @input="(e) => $emit('input', e)"
      />
      <p v-if="type === 'checkbox' || type === 'radio'">
        {{ label }}
      </p>
      <img v-if="icon" :src="props.icon" alt="" />
    </div>

    <textarea
      v-else
      v-bind="{ ...filteredAttrs, class: classes }"
      v-model="stringValue"
      :disabled="disabled"
      :checked="checked"
      :maxlength="String($attrs?.maxLength ? $attrs?.maxLength : '1000')"
      :type="type"
      :class="`${
        error ? ' animate-nudge focus:border-error' : 'focus:border-grey-2'
      } focus:text-black-2 rounded-xl border-[1px] border-grey-4 bg-white p-1 pl-2 text-black-0 outline-none transition-all duration-100 disabled:opacity-50 
      ${classes}`"
      @input="(e) => $emit('input', e)"
    />
  </div>
</template>
<style scoped>
.animate-nudge {
  animation: nudge 0.4s linear;
}

@keyframes nudge {
  0% {
    transform: rotate(-1deg);
  }
  33% {
    transform: rotate(1deg);
  }
  66% {
    transform: rotate(-1deg);
  }
}

/* Estilos padrão para input checkbox */
input[type='checkbox'] {
  padding-top: 0.9375rem; /* 15px */
  appearance: none;
  -webkit-appearance: none;
  display: inline-block;
  width: 1.125rem; /* 18px */
  height: 1rem; /* 16px */
  border: 1px solid #ccc;
  background-color: #fff;
  vertical-align: middle;
  border-radius: 0.125rem; /* 2px */
  position: relative; /* Necessário para o ícone de check */
}

/* Estilo para o checkbox marcado */
input[type='checkbox']:checked {
  background-color: white;
  border-color: #4caf50;
}

/* Adicionar o ícone de "check" */
input[type='checkbox']:checked::after {
  content: '✓'; /* Símbolo de check */
  color: #8dc63f;
  font-size: 0.875rem; /* 14px */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Ajuste específico para Safari */
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    input[type='checkbox'] + label {
      transform: translateY(-0.625rem); /* -10px */
      margin-top: 0;
      display: inline-block;
      vertical-align: middle;
      line-height: 2; /* Ajuste de altura de linha */
    }
  }
}
</style>
