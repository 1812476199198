<script setup lang="ts">
import greenAddIcon from '@/assets/icons/green/add.svg'
import greyAddIcon from '@/assets/icons/grey/add.svg'
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useToast } from 'vue-toastification'
const props = defineProps<{
  modelValue?: unknown
  disableAdd: boolean
  disableInput: boolean
  error?: string
  list: string[]
  preValidator: (v: string) => boolean
  postValidator: (v: string) => Promise<boolean>
}>()

const toast = useToast()
const MAX_LIST_LENGTH_DUE_TO_AWS_LIMIT = 40
const emit = defineEmits(['update:modelValue', 'input', 'submit'])
const chipList = ref<string[]>([])
const chipInput = ref('')
const value = computed({
  get() {
    return chipInput.value
  },
  set(value) {
    chipInput.value = value
    emit('update:modelValue', value)
  },
})

const errorString = ref('')
const { t } = useI18n()

async function addChip(value: string) {
  if (!value) return Promise.resolve()
  if (!props.preValidator(value)) {
    errorString.value = t('general.invalidEmail')
    return
  }
  if (
    chipList.value.find((s) => s == value) ||
    props.list.find((s) => s === value) ||
    (props.postValidator && !(await props.postValidator(value)))
  ) {
    errorString.value = t('general.duplicateEmail')
    return
  }

  errorString.value = ''
  chipList.value.push(value)
  chipInput.value = ''
}

async function handleKeyUp(e: KeyboardEvent) {
  const inputValue = (e.target as HTMLInputElement)?.value
  if (e.key !== ';') return
  const valueWithoutSemiColon = inputValue
    .split('')
    .filter((c) => c !== ';')
    .join('')
  chipInput.value = valueWithoutSemiColon

  addChip(valueWithoutSemiColon)
}

async function handlePaste(e: Event) {
  e.preventDefault()
  const clipboardData = await navigator.clipboard.readText()
  const emailsToAdd = clipboardData.split(';').map((e) => e.trim())
  for (const index in emailsToAdd) {
    const email = emailsToAdd[index]

    if (
      props.list.length + chipList.value.length + 1 >
      MAX_LIST_LENGTH_DUE_TO_AWS_LIMIT
    ) {
      toast.error(
        t('corporate.member.addMembersDialog.maxInviteNumberAchieved', {
          number: MAX_LIST_LENGTH_DUE_TO_AWS_LIMIT,
        })
      )
      break
    }
    addChip(email)
  }
}
async function handleSubmit() {
  addChip(chipInput.value).then(() => {
    emit('submit', chipList.value)
    chipList.value = []
  })
  chipInput.value = ''
  errorString.value = ''
}

watch(
  () => props,
  () => {
    if (!props.list.length) chipList.value = []
  }
)
</script>
<template>
  <div>
    <div class="flex max-h-10 bg-white p-2 text-grey-1">
      <div class="flex grow flex-nowrap overflow-x-auto overflow-y-hidden">
        <Chip
          v-for="chip in chipList"
          :key="chip"
          class="my-1 flex flex-nowrap bg-grey-6 text-grey-2"
        >
          {{ chip }}
        </Chip>
        <input
          v-model="value"
          class="flex grow overflow-hidden outline-none"
          :class="chipList.length == 40 ? 'w-0' : 'min-w-full'"
          :disabled="disableInput"
          @keyup="handleKeyUp"
          @keyup.delete="() => chipInput == '' && chipList.pop()"
          @keyup.enter="handleSubmit"
          @paste="handlePaste"
        />
      </div>
      <span class="right-2 top-[calc(50%-12px)] ml-2 flex min-w-fit">
        <button type="button" :disabled="disableAdd" @click="handleSubmit">
          <img :src="disableAdd ? greyAddIcon : greenAddIcon" />
        </button>
      </span>
    </div>
    <div class="my-0.5 ml-2 h-[1.5rem] text-sm text-error">
      {{ errorString }}
    </div>
  </div>
</template>

<style scoped>
::-webkit-scrollbar {
  width: 0;
  height: 0;
}
@-moz-document url-prefix() {
  * {
    scrollbar-width: none;
  }
}
</style>
